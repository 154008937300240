import Vue from 'vue';
import ApiRequest from '@services/ApiRequestService';
import SimpleValidation from '@mixins/SimpleValidation';
export default Vue.extend({
    mixins: [SimpleValidation],
    methods: {
        isNewsletterChecked() {
            return this.newsletter.length === 2;
        },
        submit(type) {
            this.$validator.validateAll(type).then((result) => {
                if (result) {
                    let data = {
                        email: this.email,
                        password: this.password,
                        newsletter: 'register' === type ? this.isNewsletterChecked() : null,
                        remember: 'login' === type ? this.remember[0] : null,
                    };
                    ApiRequest.post('/' + type, data).then((response) => {
                        location.reload();
                    }, (error) => {
                        let errors = error.response.data.errors;
                        this.$displayErrors(this.$validator, errors, type);
                    });
                    return;
                }
            });
        },
    },
});
